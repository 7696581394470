<template>
    <div class="marketplace">
        <div class="marketplace__title">
            Выберите маркетплейс
        </div>
        <div class="marketplace__content">
            <a :href="links.ozon" target="_blank" class="button button-market button-ozon">
                <IconOzon />
            </a>
            <a :href="links.wildberries" target="_blank" class="button button-market button-wb" v-if="wildEnabled">
                <IconWildBerries />  
            </a>
        </div>
        <div class="marketplace__store">
            <a href="/store">Розничные магазины на карте</a>
        </div>
    </div>
</template>

<script>
import IconOzon from '@/assets/img/svg/ozon.svg?inline';
import IconWildBerries from '@/assets/img/svg/wildberries.svg?inline';
import './marketplace-selector.sass';

export default {
    components: {
       IconOzon,
       IconWildBerries
    },
    data() {
        return {
            ozonDefault: process.env.VUE_APP_LINK_OZON,
            wildDefault: process.env.VUE_APP_LINK_WILDBERRIES,
            wildEnabled: false
        }
    }, 
    computed: {
        name() {
            return process.env.VUE_APP_NAME.toLowerCase();
        },
        links() {
            const text = this.$store.state.default.product?.replace(/[ ()$&+]/g, "+") ?? 'nitto фильтры';

            const ozon = `${this.ozonDefault}&text=${encodeURI(text)}`;
            const wildberries = `${this.wildDefault}/catalog/0/search.aspx?sort=popular&search=${encodeURI(text)}`;
            
            return { ozon, wildberries }
        }
    },
}
</script>
