export default {
    data() {
        return {
            device: null,
            devices: {
                laptop: 1024,
                tablet: 920,
                mobile: 540    
            },
            deviceWidth: null,
        }
    }, 
    computed: {
        deviceScreen() {
            if (this.device == 'mobile' || this.device == 'tablet') {
                return 'small';
            }
            return 'default';
        }
    },
    methods: {
        RESIZE() {
            this.device = this.GET_DEVICE(this.devices);
            this.deviceWidth = window?.innerWidth;
        },
        GET_DEVICE(devices) {
            let device = 'desktop';
            if (window) {
                const width = window.innerWidth;
                for (const name in devices) {
                   if (width <= devices[name]) {
                        device = name;
                   }
                }
            }
            return device;    
        }
    },
    async mounted() {
        this.$nextTick(() => {
            this.RESIZE();
            if (window) {    
                window.addEventListener('resize', this.RESIZE);
            }
        });
    },
    beforeDestroy() {
        if (window) {
            window.removeEventListener('resize', this.RESIZE);
        }
    },
}