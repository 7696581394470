<template>
    <div class="header" >
        <div class="uk-container uk-container-large header-navbar" uk-navbar>
            <div class="uk-navbar-left header-navbar-left">
                <a href="/">
                    <LogoNitto 
                        viewBox="0 0 101 38" 
                        :class="['logo', {'logo-default': menuEnabled}]" 
                    />
                </a>
            </div>
            <div class="uk-navbar-right header-navbar-right">
                <ul v-if="!mobile" class="uk-navbar-nav header-navbar-nav">
                    <li  
                        v-for="(item, index) in menu"   
                        :class="['header-link', {'is-active': !$route.path.indexOf(item.href)}]"
                        :key="index"
                    >
                        <a :href="item.href">
                            {{ item.title }}
                        </a>
                    </li>
                </ul>

                <template v-else>
                     <div class="header-hamburger">
                        <div v-if="!menuEnabled" class="hamburger-menu" @click="menuEnabled = true" >
                            <IconHamburger viewBox="0 0 30 30" />
                        </div>
                        <div v-else class="hamburger-menu hamburger-menu-close" @click="menuEnabled = false">
                            <IconClose viewBox="0 0 20 20" />
                        </div>
                    </div>

                    <div class="menu" :class="{'menu-open' : menuEnabled}">
                        <div class="menu-navbar">
                            <ul class="uk-nav menu-navbar-nav" uk-nav>
                                <li  
                                    v-for="(item, index) in menu"   
                                    :class="['menu-link', {'is-active': !$route.path.indexOf(item.href)}]"
                                    :key="index"
                                >
                                    <a :href="item.href">
                                        {{ item.title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            
            </div>
        </div>
    </div>
</template>

<script>
import mixScreenDetect from '@/mixins/screen-resize.js';
import LogoNitto from '@/assets/img/svg/logo-nitto.svg?inline';
import IconHamburger from '@/assets/img/svg/hamburger.svg?inline';
import IconClose from '@/assets/img/svg/close.svg?inline';
import MenuJson from '@/assets/data/menu.json';

export default {
    name: 'site-header',
    mixins: [mixScreenDetect],
    components: {
        LogoNitto,
        IconHamburger,
        IconClose
    },
    data() {
        return {
            menuEnabled: false,
            mobile: false
        }
    },
    watch: {
        menuEnabled(value) {
            if (document.body && value) {
                document.body.classList.add('modal-open');
            }
            if (document.body && !value) {
                document.body.classList.remove('modal-open');
            }
        },
        deviceWidth(screen) {
            this.mobile = screen < 768;
        }
    },
    computed: {
        menu() {
            return MenuJson.filter(item => {
                return item.components.includes(this.$options.name);
            });
        }
    },
}
</script>
