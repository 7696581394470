import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main-page',
        component: () => import(/* webpackChunkName: "main-page" */ '../views/MainPage')
    },
    {
        path: '/store',
        name: 'store-map',
        component: () => import(/* webpackChunkName: "store-map" */ '../views/StoreMap')
    },
    {
        path: '/articles',
        name: 'articles',
        component: () => import(/* webpackChunkName: "articles" */ '../views/ArticlesPage')
    },
    {
        path: '/partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "partners" */ '../views/Partners')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutCompany')
    },
    {
        path: '/catalog',
        name: 'catalog',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/Catalog')
    },
    {
        path: '/catalog/oil',
        name: 'catalog-oil',
        component: () => import(/* webpackChunkName: "catalog-oil" */ '../views/products/ProductOil')
    },
    {
        path: '/catalog/cabin',
        name: 'catalog-cabin',
        component: () => import(/* webpackChunkName: "catalog-cabin" */ '../views/products/ProductCabin')
    },
    {
        path: '/catalog/fuel',
        name: 'catalog-fuel',
        component: () => import(/* webpackChunkName: "catalog-fuel" */ '../views/products/ProductFuel')
    },
    {
        path: '/catalog/air',
        name: 'catalog-air',
        component: () => import(/* webpackChunkName: "catalog-air" */ '../views/products/ProductAir')
    },
    {
        path: '/catalog/:article',
        name: 'catalog-article',
        component: () => import(/* webpackChunkName: "product-page" */ '../views/ProductPage')
    },
    {
        path: '*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound')
    },
];


export function createRouter() { 
    return new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}