import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueYandexMetrika from "vue-yandex-metrika";
import VueAnalytics from "vue-analytics";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "uikit";
import "uikit/dist/css/uikit.min.css";
import "@/assets/style.sass";

import App from './App.vue'
import { createRouter } from './router';
import { createStore } from './storage';

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(VueReCaptcha, { siteKey: `${process.env.VUE_APP_CAPTCHA_SITE_KEY}` });

const router = createRouter();
const store = createStore();

const app = new Vue({
    router,
    store,
    render: (h) => h(App),
});

const debug = process.env.VUE_APP_ENV;

if (!!process.env.VUE_APP_METRIC_YANDEX) {
    Vue.use(VueYandexMetrika, {
        id: process.env.VUE_APP_METRIC_YANDEX,
        router: router,
        env: debug,
    });
}

if (!!process.env.VUE_APP_METRIC_GOOGLE) {
    Vue.use(VueAnalytics, {
        id: process.env.VUE_APP_METRIC_GOOGLE,
        router: router,
        disabled: debug !== "production",
    });
}

Vue.directive("click-outside", {
    inserted: (el, binding, vnode) => {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
    stopProp(event) {
        event.stopPropagation();
    },
});

Vue.directive("scroll", {
    inserted: function (el, binding) {
        let func = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener("scroll", func);
            }
        };
        window.addEventListener("scroll", func);
    },
});

app.$mount("#app");
