<template>
    <div id="app">
        <header-view  />  
        <router-view 
            @bodyTheme="theme = $event.theme"
        /> 
        <footer-view />

        <modal-window :window-small="modalItem == 'marketplace'">
            <feedback-form v-if="modalItem == 'feedback'" />
            <marketplace-selector v-if="modalItem == 'marketplace'" />
        </modal-window>

        <alert-message />
    </div>
</template>

<script>
import HeaderView from './components/Header'
import FooterView from './components/Footer'
import ModalWindow from './components/modal-window/ModalWindow'
import FeedbackForm from '@/components/feedback-form/FeedbackForm';
import MarketplaceSelector from '@/components/markeplace-selector/MarketplaceSelector';
import AlertMessage from '@/components/Alert';

export default {
    name: 'App',
    components: {
        MarketplaceSelector,
        FeedbackForm,
        HeaderView,
        FooterView,
        ModalWindow,
        AlertMessage
    },
    data() {
        return {
            modalItem: null,
            theme: null,
            alert: null
        }
    },
    computed: {
        enabled() {
            return this.$store.state.modal.name;
        },
    },
    watch: {
        enabled(value) {
            if (value) {
                this.modalItem = value;
            }
        },
        theme(value) {
            if (document.body && value) {
                document.body.classList.add(value);
            }
        }
    },
}
</script>
