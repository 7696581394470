import Vue from 'vue'
import Vuex from 'vuex'
import Modal from '@/components/modal-window/storage.js'

Vue.use(Vuex)

const state = {
    product: null,
    alert: null
}

const getters = {
    
}

const mutations = {
    SET_PRODUCT(state, payload) {
        state.product = payload;
    },
    SET_ALERT(state, payload) {
        state.alert = payload;
        if (payload) {
            setTimeout(() => {state.alert = null}, 4000);
        }
    }
}

export function createStore() {
    return new Vuex.Store({
        modules: {
            default: {
                namespaced: true,
                state,
                getters,
                mutations,
            },
            modal: Modal
        }
    })
}


