<template>
    <div class="footer">
        <div class="uk-container uk-container-large footer-container">
            <div class="footer-footnote" v-if="deviceScreen == 'small'">
                <a href="/">
                    <LogoNitto viewBox="0 0 101 38" class="logo-light" />
                </a>
                <div class="button-search" @click="callModal('search')"> 
                    <IconSearch  viewBox="0 0 20 20" />
                </div>
            </div>

            <div class="footer-content">
                <div class="footer-item footer-item-left">
                    <div class="footer-item-title">
                        Продукция
                    </div>
                    <div class="footer-item-links">
                        <a href="/catalog/oil">Масляные фильтры</a>
                        <a href="/catalog/fuel">Топливные фильтры</a>
                        <a href="/catalog/air">Воздушные фильтры</a>
                        <a href="/catalog/cabin">Салонные фильтры</a>
                    </div>
                </div>

                <div class="footer-item footer-item-center">
                    <a class="footer-item-title" @click="selection">
                        Подобрать фильтр
                    </a>
                    <div class="footer-action">
                        <button class="button button-primary" @click="callModal('feedback')">
                            Написать нам
                        </button> 
                        <div class="button-search" @click="callModal('search')" style="display:none"> 
                            <IconSearch  viewBox="0 0 20 20" />
                        </div>
                    </div>
                </div>

                <div class="footer-item footer-item-right">
                    <div class="footer-logo">
                        <a href="/">
                            <LogoNitto viewBox="0 0 101 38" class="logo-light" />
                        </a>
                    </div>
                    <div class="" uk-navbar>
                        <ul class="uk-navbar-nav footer-navbar-nav">
                            <li  
                                v-for="(item, index) in menu"   
                                :class="['footer-link', {'is-active': !$route.path.indexOf(item.href)}]"
                                :key="index"
                                :style="item.style"
                            >
                                <a :href="item.href">
                                    {{ item.title }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import mixScreenDetect from '@/mixins/screen-resize.js';
import LogoNitto from '@/assets/img/svg/logo-nitto.svg?inline';
import IconSearch from '@/assets/img/svg/search.svg?inline';
import MenuJson from '@/assets/data/menu.json';

export default {
    name: 'site-footer',
    mixins: [mixScreenDetect],
    components: {
        LogoNitto,
        IconSearch
    },
    computed: {
        menu() {
            return MenuJson.filter(item => {
                return item.components.includes(this.$options.name);
            });
        }
    },
    methods: {
        callModal(prop) {
            this.$store.commit('modal/CALL_MODAL', prop)
        },
        selection() {
            const selection = document.getElementById('selection')
            if (selection) {
                window.scrollTo({
                    top: selection.offsetTop - 60,
                    behavior: "smooth",
                });
            } else { 
                window.location.href = '/catalog'
            }
        }
    }
}
</script>