<template>
    <div :class="['alert', {'alert-show': show && message }]">
        <div class="alert-content">
            <span><IconError viewBox="0 0 12 12" /></span> {{ message }}
        </div>
    </div>
</template>

<script>
import IconError from '@/assets/img/svg/IconError.svg?inline';

export default {
    components: {
        IconError
    },
    data() {
        return {
            message: '',
        }
    },
    computed: {
        show() {
            return !!this.alert;
        },
        alert() {
            return this.$store.state.default.alert;
        }
    },
    watch: {
        show(value) {
           if (value) {
                this.message = this.alert;
           }
        }
    }
}
</script>

<style lang="sass">
    @import '@/assets/sass/alert.sass'
</style>